const BlogData = [
    {
      id: 1,
      title: 'Which LEGO Theme Has The Best Minifigures?',
      slug: 'which-lego-theme-has-the-best-minifigures',
      img: 'which-lego-theme-has-the-best-minifigures.png',
      alt: 'LEGO Star Wars, Lord of the Rings, Marvel, and CMF theme logos',
      summary: 'In this article, I take a look at the average rating of minifigures across all LEGO themes to see which has the best minifigures.',
      date: '28th March 2024',
      author: 'Graham',
      content: (
        <>
            <p class="mb-2">With over 215,000 user ratings now submitted, I thought it was time to run some analysis on which theme has the best minifigures overall.</p>
            <p class="mb-2">If you take a look at the <a href="https://brickelo.com/results" class="text-green-400 font-bold">top 100 minifigures</a>, you'll see that it's largely dominated by licensed themes, such as Lord of the Rings, Pirates of the Caribbean, Star Wars, Marvel, and DC. But do these minifigures offer up the best minifigures overall? Let's take a look...</p>
            <p class="mb-2">Here are the top 5 themes by average minifigure rating:</p>
            <div class="flex justify-center items-center">
            <table class="w-full mb-6">
                    <thead class="border-b border-white border-opacity-50">
                        <tr class="text-green-400 font-bold">
                            <th>Theme</th>
                            <th className="text-center">Avg. Minifigure Rating</th>
                            <th className="text-center">No. of Minifigures</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">The LEGO Batman Movie</td>
                          <td class="py-1 text-center">1079.76</td>
                          <td class="py-1 text-center">44</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Pirates of the Caribbean</td>
                          <td class="py-1 text-center">1070.16</td>
                          <td class="py-1 text-center">41</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">The Lord of the Rings</td>
                          <td class="py-1 text-center">1069.73</td>
                          <td class="py-1 text-center">64</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">The LEGO Movie 2</td>
                          <td class="py-1 text-center">1065.74</td>
                          <td class="py-1 text-center">19</td>
                        </tr>
                        <tr class="border-b border-white border-opacity-50">
                          <td class="py-1 text-left">Monster Fighters</td>
                          <td class="py-1 text-center">1055.69</td>
                          <td class="py-1 text-center">21</td>
                        </tr>           
                    </tbody>
                </table>
                </div>

                <p class="mb-2">A little suprisingly, The LEGO Batman Movie claims the top spot for now. Although the theme doesn't have any minifigures in the top 100 (at the time of writing <a href="https://brickelo.com/results/the-lego-batman-movie/minifigures/coltlbm24" class="text-green-400 font-bold">Baturion</a> is the highest placed a #149), all but three minifigures from the theme have ratings higher than the starting rating of 1,000. The theme offers up many playful variants of Batman and some unique and interesting supervillian minifigures, all of which are highly detailed.</p>
                <p class="mb-2">It's less of a surpise to see The Lord of the Rings and Pirates of the Caribbean up there, as these themes are well represented in the <a href="https://brickelo.com/results" class="text-green-400 font-bold">top 100</a>. You might expect to see Star Wars up there, but given the theme has well over 1,000 minifigures, many are bound to be subpar and therefore bring the average down.</p>
                <p class="mb-2">Another metric would be to look at the number of minifigures each theme has in the top 100:</p>
                <table class="w-full mb-6">
                    <thead class="border-b border-white border-opacity-50">
                        <tr class="text-green-400 font-bold">
                            <th className="text-left">Theme</th>
                            <th className="text-center">No. of Minifigures in Top 100</th>
                        </tr>
                    </thead>
                    <tbody class="border-b border-white border-opacity-50">
                        <tr>
                          <td class="py-1 text-left">Star Wars</td>
                          <td class="py-1 text-center">38</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Marvel</td>
                          <td class="py-1 text-center">18</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">DC</td>
                          <td class="py-1 text-center">12</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">The Lord of the Rings</td>
                          <td class="py-1 text-center">11</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Collectible Minifigures</td>
                          <td class="py-1 text-center">11</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Pirates of the Caribbean</td>
                          <td class="py-1 text-center">8</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Ideas</td>
                          <td class="py-1 text-center">1</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Disney</td>
                          <td class="py-1 text-center">1</td>
                        </tr>              
                    </tbody>
                </table>

                <p class="mb-2">With this measurement, Star Wars is a clear winner as over a third of the minifigures in the top 100 come from the theme. However, we should remember that in terms of minifigure count, Star Wars is LEGO's biggest theme, so you would expect such a result. It's also the most popular and most collectible theme, and this bias will undoubtedly be having some effect. Once I have more user ratings, I'll provide a more in-depth analysis that shows the percentage of theme minifigures in the top 100, top 500, and top 1,000 etc.</p>

                <p class="mb-2">Finally, here are the worst performing themes:</p>
                <div class="flex justify-center items-center">
                <table class="w-full mb-6">
                    <thead class="border-b border-white border-opacity-50">
                        <tr class="text-green-400 font-bold">
                            <th>Theme</th>
                            <th className="text-center">Avg. Minifigure Rating</th>
                            <th className="text-center">No. of Minifigures</th>
                        </tr>
                    </thead>
                    <tbody class="border-b border-white border-opacity-50"> 
                        <tr>
                          <td class="py-1 text-left">Jurassic World</td>
                          <td class="py-1 text-center">935.83</td>
                          <td class="py-1 text-center">123</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Harry Potter</td>
                          <td class="py-1 text-center">939.82</td>
                          <td class="py-1 text-center">507</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Avatar</td>
                          <td class="py-1 text-center">952.58</td>
                          <td class="py-1 text-center">27</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Agents</td>
                          <td class="py-1 text-center">954.93</td>
                          <td class="py-1 text-center">33</td>
                        </tr>
                        <tr>
                          <td class="py-1 text-left">Alpha Team</td>
                          <td class="py-1 text-center">957.82</td>
                          <td class="py-1 text-center">30</td>
                        </tr>     
                    </tbody>
                </table>
                </div>

                <p class="mb-2">Jurassic World comes bottom of the pile and let's face it, no one collects Jurassic World sets for the minifigures. The big surprise here is Harry Potter, given that it's another highly popular theme. However, many of the minifigures are very plain, and how many versions of Harry, Ron, and Hermione have we got? Avatar is also low down, with its long-legged minifigures dividing opinion. I for one quite like them, but I'm in the minority.</p>

                <p class="mb-2">Thanks for reading, and remember you can vote on your favourite minifigures <a href="https://brickelo.com/ranking" class="text-green-400 font-bold">here!</a></p>
        </>
      ),
    },
    // Add more blog entries as needed
  ];
  
  export default BlogData;