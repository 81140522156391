import { createBrowserRouter, Route, Link, NavLink, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { useState, useEffect } from 'react';

//pages
import Home, { randomTopMinifiguresLoader } from './pages/Home';
import ResultsPage from './pages/ResultsPage';
import MinifigurePage from './pages/MinifigurePage';
import CharacterPage from './pages/CharacterPage';
import RankingPage from './pages/RankingPage';
import ThemePage from './pages/ThemePage';
import BlogPage from './pages/BlogPage';

//layouts
import RootLayout from './layouts/RootLayout';
import RankingLayout from './layouts/RankingLayout';
import ResultsLayout, { topMinifiguresLoader } from './layouts/ResultsLayout';
import ThemesLayout, {averageRatingsLoader} from './layouts/ThemesLayout';
import CompareLayout from './layouts/CompareLayout';
import BlogLayout from './layouts/BlogLayout';
import MinifigureError from './components/MinifigureError';
import NotFound from './components/NotFound';

function App() {
  const [themesData, setThemesData] = useState([]);

  //The themesLoader is used to get the list of themes for use in the navigation bar on the results page (i.e. ResultsLayout component)
  useEffect(() => {
    const fetchData = async () => {
      const data = await themesLoader();
      setThemesData(data);
    };

    fetchData();
  }, []);
  
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout/>}>
          <Route index element={<Home/>} loader={randomTopMinifiguresLoader}/>

          <Route path="ranking" element={<RankingLayout themesData={themesData}/>} errorElement={<MinifigureError/>}>
              <Route path=":themeUrl" element={<RankingPage themesData={themesData}/>}/>
          </Route>  

          <Route path="results" element={<ResultsLayout themesData={themesData}/>} loader={topMinifiguresLoader}>
              <Route path="minifigures/:searchValue" element={<CharacterPage/>}/> 
              <Route path=":themeUrl" element={<ResultsPage themesData={themesData}/>}>
                  <Route path="minifigures/:searchValue" element={<MinifigurePage/>}/>
              </Route>
          </Route>

          <Route path="themes" element={<ThemesLayout themesData={themesData}/>} loader={averageRatingsLoader}>
            <Route path=":themeUrl" element={<ThemePage themesData={themesData}/>}/>
          </Route>  

          <Route path="compare" element={<CompareLayout/>}/>

          <Route path="blog" element={<BlogLayout />}>
            <Route path=":slug" element={<BlogPage />} />
          </Route> 

          <Route path="*" element={<NotFound/>}/>
      </Route>
    )
  )

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

//loader function
export const themesLoader = async () => {
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const response = await fetch(`${apiUrl}/getThemes.php`)
  return response.json()
}
